<template>
  <div v-loading="loading">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span class="title_fw">项目数量统计</span>
        <span class="title_right"
          ><SearchUserDate
            :clearable="true"
            :type="'projectQuantit'"
            :introductionType="'month'"
            :width="'100'"
            :list="monthGenerate"
            :yearValue="sectionInfo.quantityYear"
            v-model="sectionInfo.quantityMonth"
            @change="onQuantitChange"
        /></span>
        <span class="title_right"
          ><SearchUserDate
            :type="'projectQuantit'"
            :introductionType="'month'"
            :width="'100'"
            :yearIsShow="true"
            :title="titleYear"
            :list="dictData.yearList"
            :twoValue="sectionInfo.quantityMonth"
            v-model="sectionInfo.quantityYear"
            @change="onQuantitChange"
        /></span>
      </div>
      <div class="project_individual" v-for="item in quantityList" :key="item.name">
        <div class="project_individual_title">
          {{ item.name }}
        </div>
        <div class="project_individual_center">{{ item.value }}</div>
      </div></el-card
    >
    <el-card class="box-card" style="margin-top: 8px">
      <div slot="header" class="clearfix">
        <span class="title_fw">
          <!-- <div> -->
          项目统计表
          <span style="margin-left: 10px; font-size: 14px; color: #409eff"
            >以项目创建时间来统计(点击项目名称即可查看该项目收入月度统计)
          </span>
          <!-- </div> -->

          <!-- <div style="margin-left: 10px; font-size: 14px; color: #409eff">
            
          </div> -->
        </span>
        <span class="title_right"
          ><SearchUserDate
            :type="'projectStatistics'"
            :introductionType="'projectManager'"
            :twoIntroductionType="'projectId'"
            :width="'100'"
            :yearIsShow="true"
            :title="titleYear"
            :list="dictData.yearList"
            :twoValue="sectionInfo.projectStatisticsManager"
            :threeValue="sectionInfo.projectStatisticsId"
            v-model="sectionInfo.statisticsYear"
            @change="onStatisticsChange"
        /></span>
        <span class="title_right"
          ><SearchUserDate
            :title="'项目经理：'"
            :width="'100'"
            :type="'projectStatistics'"
            :introductionType="'projectManager'"
            :twoIntroductionType="'projectId'"
            :clearable="true"
            :filterable="true"
            :list="projectManagerlist"
            :yearValue="sectionInfo.statisticsYear"
            :twoValue="sectionInfo.projectStatisticsManager"
            :threeValue="sectionInfo.projectStatisticsId"
            v-model="sectionInfo.projectStatisticsManager"
            @change="onStatisticsChange"
        /></span>
        <span class="title_right"
          ><SearchUserDate
            :title="'项目名称：'"
            :width="'250'"
            :type="'projectStatistics'"
            :introductionType="'projectManager'"
            :twoIntroductionType="'projectId'"
            :threeIsShow="true"
            :clearable="true"
            :filterable="true"
            :list="projectList"
            :yearValue="sectionInfo.statisticsYear"
            :twoValue="sectionInfo.projectStatisticsManager"
            :threeValue="sectionInfo.projectStatisticsId"
            v-model="sectionInfo.projectStatisticsId"
            @change="onStatisticsChange"
        /></span>
        <el-button class="title_right" type="primary" size="small" @click="isDialog = true"
          >数据来源说明</el-button
        >
      </div>
      <el-table
        :summary-method="getSummaries"
        show-summary
        max-height="550"
        border
        :cell-class-name="setCellClass"
        @cell-click="onCellClickFn"
        :data="statisticsList"
      >
        <el-table-column
          type="index"
          label="序号"
          align="center"
          fixed="left"
          width="70"
        ></el-table-column>
        <el-table-column
          prop="projectName"
          label="项目名称"
          align="center"
          fixed="left"
          min-width="240"
        >
          <template slot-scope="scope">
            <a style="color: blue; text-decoration: underline" href="javascript:void(0)">
              {{ scope.row.projectName }}
            </a>
          </template>
        </el-table-column>
        <el-table-column
          prop="projectManager"
          min-width="100"
          label="项目经理"
          fixed="left"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="projectSource"
          min-width="100"
          label="项目性质"
          sortable
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.projectSource | dict(dictData.projectQuality) }}
          </template></el-table-column
        >
        <el-table-column
          prop="projectSource"
          min-width="100"
          label="项目来源"
          sortable
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.projectSource | dict(dictData.projectSource) }}
          </template></el-table-column
        >

        <el-table-column
          prop="businessType"
          min-width="100"
          label="业务种类"
          sortable
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.businessType | dict(dictData.businessLines) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="contractingCompany"
          min-width="100"
          label="承接公司"
          sortable
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.contractingCompany | dict(dictData.companyType) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="contractSigned"
          min-width="90"
          sortable
          label="是否签署合同"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.contractSigned > 0 ? '已签署' : '未签署' }}
          </template>
        </el-table-column>
        <el-table-column prop="predictContractAmount" min-width="190" sortable align="right">
          <template slot-scope="{}" slot="header">
            <span>我方合同金额</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>项目中的我方合同金额</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.predictContractAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="contractAmount" min-width="200" sortable align="right">
          <template slot-scope="{}" slot="header">
            <span>合同实际金额</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>金额前面有（大），该项目即为大包合同子项</div>
                <div>项目详情里的合同实际金额</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.isLargePackage == 1 ? '（大）' : '' }}
            {{ scope.row.contractAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column
          prop="actualAmount"
          min-width="130"
          label="项目实际金额"
          sortable
          align="right"
        >
          <template slot-scope="scope">
            {{ scope.row.actualAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column
          prop="paymentStatus"
          min-width="90"
          label="项目收款情况"
          sortable
          align="center"
        >
        </el-table-column>
        <el-table-column prop="invoiceAmount" min-width="130" sortable align="right">
          <template slot-scope="{}" slot="header">
            <span>已开票金额</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>项目收入中的已开票金额</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.invoiceAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="repaymentAmount" min-width="130" sortable align="right">
          <template slot-scope="{}" slot="header">
            <span>已收款金额</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>项目收入中的已收款金额</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.repaymentAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="productionPerformance" min-width="130" sortable align="right">
          <template slot-scope="{}" slot="header">
            <span>实际生产绩效</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>项目结算中的实际生产绩效</div>
              </div></el-tooltip
            >
          </template>

          <template slot-scope="scope">
            {{ scope.row.productionPerformance | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column
          prop="resourceAllocation"
          min-width="100"
          label="项目投入人力"
          sortable
          align="right"
        >
          <template slot-scope="{}" slot="header">
            <span>项目投入人力</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>项目实际投入人力合计</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.resourceAllocation | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="laborCost" min-width="130" sortable align="right">
          <template slot-scope="{}" slot="header">
            <span>人工成本</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>项目人员成本合计，</div>
                <div>前一周的成本</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.laborCost | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="otherCost" min-width="130" sortable align="right">
          <template slot-scope="{}" slot="header">
            <span>其他成本</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>项目产生的报销费用、付款申请费用、</div>
                <div>外协生产费用、设备折旧费用合计</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.otherCost | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="totalCost" min-width="130" label="总成本" sortable align="right">
          <template slot-scope="{}" slot="header">
            <span>总成本</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>人员成本+其他成本</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.totalCost | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column
          prop="actualStartDate"
          min-width="120"
          label="实际开始时间"
          sortable
          align="center"
        >
          <template slot-scope="{}" slot="header">
            <span>实际开始时间</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>项目实际开始时间</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.actualStartDate | dateFormat }}
          </template>
        </el-table-column>
        <el-table-column
          prop="actualEndDate"
          min-width="120"
          label="实际结束时间"
          sortable
          align="center"
        >
          <template slot-scope="{}" slot="header">
            <span>实际结束时间</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>项目实际结束时间</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.actualEndDate | dateFormat }}
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <el-dialog width="75%" title="数据来源说明" :visible.sync="isDialog">
      <div style="padding: 5px">
        <img src="@/assets/项目统计-项目统计表.png" alt="数据来源说明" width="100%" />
      </div>
    </el-dialog>
    <ProjectMoneyMonthDialog
      v-if="isShowProject"
      :isShow.sync="isShowProject"
      :projectId="projectMoneyPorjectId"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  components: {
    SearchUserDate: () => import('../searchUserDate.vue'),
    ProjectMoneyMonthDialog: () => import('./projectMoneyMonthDialog.vue'),
  },
  data() {
    return {
      loading: false, // 加载
      projectList: [], // 项目数组
      dictData: {
        yearList: [],
        businessLines: [],
        projectSource: [],
        businessType: [],
        companyType: [],
        projectQuality: [],
      },
      quantityList: [], // 项目数量统计
      statisticsList: [], // 项目统计表

      sectionInfo: {
        // 条件筛选
        quantityYear: null, // 年份
        statisticsYear: null,
        quantityMonth: null,
        projectStatisticsId: null, // 项目统计项目名称
        projectStatisticsManager: '', // 项目统计项目经理
      },
      projectManagerlist: [], //项目经理
      titleYear: '统计时间：',
      isDialog: false,
      contractAmount: 0,
      isShowProject: false,
      projectMoneyPorjectId: '',
    }
  },
  provide() {
    return {}
  },
  computed: {
    monthGenerate() {
      const arr = []
      for (let index = 0; index < 12; index++) {
        arr.push({ id: index + 1, dictName: `${index + 1}月`, dictVal: `${index + 1}` })
      }
      return arr
    },
  },
  created() {
    this.getType()
  },
  filters: {
    applyAmount(n) {
      if (n) {
        if (n === null || n === undefined) return ''
        // 转换为数字并保留两位小数
        let number = parseFloat(n).toFixed(2)
        // 增加千分位
        let parts = number.split('.')
        let integerPart = parts[0]
        let decimalPart = parts[1]
        // 使用正则表达式添加千分位
        integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

        return `${integerPart}.${decimalPart}`
      } else {
        return n
      }
    },
  },
  watch: {},
  mounted() {},
  methods: {
    init(val) {
      this.loading = true
      Promise.all([
        this.$api.statistics.queryProjectCountSummary(`date=${val}`),
        this.$api.statistics.queryProjectSummary(`date=${val}`),
      ])
        .then(res => {
          // 项目数量统计
          this.quantityList = []
          this.statisticsList = []
          const { projectCountStatisticsVo = {} } = res[0].data
          const keyMappings = {
            notStarted: '未启动',
            inprogress: '正常实施中',
            delayed: '进度滞后',
            maintenance: '运维服务期（质保期）',
            temporarilyPaused: '临时暂停',
            terminatedPendingPayment: '终止（待收款）',
            terminatedNoPayment: '终止（无待收款）',
            completedPaid: '已完结已收款',
            completedPendingPayment: '已完结待收款',
            archived: '归档',
          }
          this.quantityList = Object.keys(projectCountStatisticsVo)
            .filter(key => keyMappings.hasOwnProperty(key)) // 只处理有映射的键
            .map(key => ({
              name: keyMappings[key], // 根据键获取名称
              value: projectCountStatisticsVo[key],
            }))

          this.statisticsList = res[1].data?.projectStatisticsView?.projectStatisticsVoList || []
          this.contractAmount = res[1].data?.projectStatisticsView?.projectPaymentAmount || 0
          this.loading = false
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    onQuantitChange(arr) {
      this.quantityList = arr
    },
    onStatisticsChange(obj) {
      this.statisticsList = obj?.projectStatisticsVoList || []
      this.contractAmount = obj?.projectPaymentAmount || 0
    },
    onCellClickFn(row, column, cell, event) {
      if (column.property === 'projectName') {
        this.projectMoneyPorjectId = row.id
        this.isShowProject = true
      }
    },
    setCellClass({ column }) {
      if (column.property === 'projectName') {
        return ['clickable']
      }
      return []
    },

    // 表格合计
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        } else if (index > 7 && index != 11 && index < 19) {
          //页面分别统计 处理
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)

            if (index === 9) {
              sums[index] = Number(this.contractAmount.toFixed(2)).toLocaleString()
            } else {
              sums[index] = Number(sums[index].toFixed(2)).toLocaleString()
            }
          } else {
            sums[index] = '' //如果列的值有一项不是数字，就显示这个自定义内容
          }
        } else {
          sums[index] = '--'
        }
      })

      return sums
    },
    getType() {
      this.$api.dict
        .listSysDictData('STATISTICS_YEAR', true)
        .then(res => {
          this.dictData.yearList = res.data
          const { dictVal = '' } = res.data[0] || null
          this.sectionInfo.quantityYear = dictVal
          this.sectionInfo.statisticsYear = dictVal
          this.init(dictVal)
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('BUSINESS_TYPE', true)
        .then(res => {
          this.dictData.businessType = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('BUSINESS_LINES', true)
        .then(res => {
          this.dictData.businessLines = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('PROJECT_SOURCE', true)
        .then(res => {
          this.dictData.projectSource = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('COMPANY_TYPE', true)
        .then(res => {
          this.dictData.companyType = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('PROJECT_NATURE', true)
        .then(res => {
          this.dictData.projectQuality = res.data
            .filter(v => v.dictVal)
            .flatMap(v =>
              v.dictVal.split(',').map((value, index) => ({
                dictName: v.dictName,
                dictVal: value,
                id: Date.now() + index,
              }))
            )
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.project
        .getProjectInfoByStaffId()
        .then(res => {
          this.projectList = res.data?.projectNameRespList.map(v => ({
            id: v.id,
            dictName: v.projectName,
            dictVal: v.id,
          }))
          this.projectManagerlist = res.data?.projectManagerNameRespList.map(v => ({
            id: v.id,
            dictName: v.userName,
            dictVal: v.id,
          }))
          this.projectManagerlist.unshift({
            id: '1',
            dictName: '全部',
            dictVal: '',
          })
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
}
</script>

<style scoped lang="scss">
.title_fw {
  font-size: 100%;
  font-weight: 700;
  color: #000;
}
.title_right {
  float: right;
  margin-left: 8px;
  font-size: 12px;
}
.statistics_top {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.project_individual {
  display: inline-block;
  text-align: center;
  width: 150px;
  font-size: 18px;
  .project_individual_title {
    font-size: 15px;
  }
  .project_individual_center {
    line-height: 35px;
    margin-bottom: 10px;
    font-weight: 900;
    color: #5c5b87;
  }
}
.text_Details_Bgc {
  //详情
  background-color: #409eff;
  color: #fff;
  padding: 5px 5px;
}
/deep/.el-card__header {
  border-bottom: 0px;
}
/deep/.clickable {
  cursor: pointer; /* 鼠标移入时显示手形光标 */
}

.clickable:hover {
  background-color: #dcdcdc; /* 鼠标悬停时更改背景色 */
}
</style>
